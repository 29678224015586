import React from 'react';
import { Menu, Item } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

function download(dataurl, filename) {
  const anchorTag = document.createElement("a");
  anchorTag.href = dataurl;
  anchorTag.setAttribute("download", filename);
  anchorTag.click();
}

function dataURItoBlob(dataURI) {
  var byteString = atob(dataURI.split(',')[1]);
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], {type: mimeString});
}

const EditorContextMenu = ({ fileName, editor }) => {
  const copyImage = async ({event}) => {
    event && event.stopPropagation();
    const data = editor.toDataURL({multiplier: 2});
    const blob = dataURItoBlob(data);
    const clipboardItemInput = new window.ClipboardItem({'image/png' : blob});
    await navigator.clipboard.write([clipboardItemInput]);
  };
  const saveImage = ({event}) => {
    event && event.stopPropagation();
    const dataUrl = editor.toDataURL({multiplier: 2});
    download(dataUrl, fileName || `AnySnap - ${Date.now()}`);
  }
  return (
    <Menu id='menu_id' animation='fade'>
       <Item onClick={copyImage}>Copy Image</Item>
       <Item onClick={saveImage}>Save Image</Item>
    </Menu>
  );
};

export default EditorContextMenu;
