import React, { useEffect, useState } from 'react';
import UndoIcon from './toolbarOptions/undo';
import RedoIcon from './toolbarOptions/redo';
import { uploadImage } from '../util/upload-image';
import { Button } from '@adaptavant/core';

const EditorOptions = ({
  editor,
  editMode,
  isRedoing,
  setObjects,
  setIsRedoing,
  contactId,
  accountId,
  fullStorageKey,
  fileName,
  setSavingImage,
  savingImage,
  objects
}) => {
  const [isModified, setModified] = useState(false);
  useEffect(() => {
    if (editor && setObjects && setIsRedoing && setModified) {
      editor.on('object:added', function () {
        if (!isRedoing) {
          setObjects([]);
        }
        setIsRedoing(false);
        setModified(Boolean(editor.getObjects().length));
      });
    }
    return () => editor && editor.off('object:added');
  }, [editor, isRedoing, setObjects, setIsRedoing, setModified]);

  const saveImage = async (event) => {
    try {
      event.stopPropagation();
      if (editor && contactId) {
        console.log('handling save button');
        setSavingImage(true);
        editor.isDrawingMode = false;
        await uploadImage(editor.toDataURL({multiplier: 2}), contactId, accountId, fullStorageKey, fileName);
        global.location.reload();
      } else {
        console.log('dropped save request');
      }
    } catch (e) {
      console.error(e);
    }
  }
  return (
    <>
      {
        (editMode && isModified) ? (
          <>
            {
              (!savingImage) ? (
                <>
                  <RedoIcon editor={editor} setObjects={setObjects} objects={objects} setIsRedoing={setIsRedoing} />
                  <UndoIcon editor={editor} setObjects={setObjects} objects={objects} />
                </>
              ) : null
            }
            <li>
            <Button variant="accentPrimary" size="large" onClick={saveImage} className="ml-4 w-28" {...(savingImage ? { isLoading: true } : {})} >
            {(savingImage) ? "" : 'Save'}
            </Button>
            </li>
          </>
        ) : null
      }
    </>
  );
}

export default EditorOptions;
